.paginationPopup {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.paginationPopupInput {
  flex-grow: 1;
}

.paginationButton {
}

.currentPageButton {
  font-weight: bold;
}
