.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.size-mini {
  font-size: 0.25em;
}

.size-tiny {
  font-size: 0.5em;
}

.size-small {
  font-size: 0.75em;
}

.size-medium {
  font-size: 1em;
}

.size-large {
  font-size: 1.25em;
}

.size-big {
  font-size: 1.5em;
}

.size-huge {
  font-size: 2em;
}

.size-massive {
  font-size: 3em;
}

.emphasized {
  font-weight: bold;
}

.monospace {
  font-family: monospace;
}

.noWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  overflow-y: visible;
}
