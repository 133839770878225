/* Generic styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0 !important;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ui.vertical.menu {
  border-radius: 0 !important;
}

/* Global variables */

:root {
  --colorError: #8f1600;
  --colorSuccess: #004d25;
  --colorLink: #3391ff;
  --colorThemeLight: #e8eef0;
}
