.faded {
  opacity: 0.35;
}

.fadeTransition {
  transition: 0.15s;
  transition-property: opacity;
}

.notFaded {
  opacity: 1;
}
