.noWrap {
  white-space: nowrap;
}

.interactable:hover {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  background-image: none !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
