.field {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: baseline;
}

.labelWrapper {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.label {
  color: rgba(0, 0, 0, 0.5);
}

.formError {
  color: #8f1600;
  margin-left: 2em;
}

.radioButton {
  font-weight: 400 !important;
}

.inactiveButton {
  background-color: #939393 !important;
}
