.container {
  display: flex;
  margin-top: 0 !important;
  height: 42px !important;
  margin-bottom: 1em;
}

.placeholder {
  margin-top: 0 !important;
  height: 42px !important;
  margin-bottom: 1em;
}

.title {
  flex-grow: 1;
}

.titleInput {
  flex-grow: 1;
}

.buttonContainer {
  display: flex !important;
}

.errorHighlight {
  color: rgb(143, 22, 0);
}

.submitting {
  opacity: 0.45 !important;
}

.secondaryActionsTrigger {
  padding: 11px !important;
}

.secondaryActionsTrigger > i {
  margin: 0 !important;
}
