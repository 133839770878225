.flexRow {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 1em;
}

.accessTokenField {
  flex-grow: 1;
  margin-bottom: 0 !important;
}

.noTokenField {
  color: gray;
}
