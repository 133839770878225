.dropdownList {
  border-bottom-color: rgb(150, 200, 218);
  border-left-color: rgb(150, 200, 218);
  border-right-color: rgb(150, 200, 218);

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;

  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;

  background-color: rgb(255, 255, 255);
}

.dropdown {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;

  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 0.1s ease, width 0.1s ease;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  border-top-style: solid;
  border-left-style: solid;
  border-right-style: solid;

  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;

  background-color: rgb(255, 255, 255);
  padding: 5px;
}

.dropdownOpenable {
  cursor: pointer;
}

.dropdownSearchable {
  cursor: text;
}

.dropdownClosed {
  border-color: rgba(34, 36, 38, 0.15);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.dropdownOpenable.dropdownClosed:hover {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;
}

.dropdownSearchable.dropdownClosed:hover {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;
}

.dropdownOpen {
  border-top-color: rgb(150, 200, 218);
  border-left-color: rgb(150, 200, 218);
  border-right-color: rgb(150, 200, 218);

  border-bottom-color: rgba(34, 36, 38, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.dropdownIcon {
  margin: 5px !important;
  align-self: center;
}

.clearIcon {
  margin: 3px !important;
  align-self: center;
}

.interactableIcon:hover {
  color: rgb(180, 180, 180);
  cursor: pointer;
}

.hiddenIcon {
  color: rgba(0, 0, 0, 0);
}

.singleSelectContentElement {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}

.singleSelectValue {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
}

.item {
  padding: 0.55em 1em;
  white-space: normal;
  word-wrap: normal;
  cursor: pointer;
}

.itemActive {
  background: rgba(0, 0, 0, 0.05);
  font-weight: bold;
}

.itemInactive:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.searchFieldNoSelections {
  padding-left: 5px;
}

.searchField {
  flex-grow: 1;
  position: relative;
  top: 0;
  left: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0);
  background-origin: padding-box;
  border-color: rgb(0, 0, 0);
  border-style: none;
  border-width: 0;
  border-image-repeat: stretch;
  border-image-source: none;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: auto;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  outline: rgb(0, 0, 0) none 0px;
  overflow-wrap: break-word;
  overflow-x: visible;
  overflow-y: visible;
  text-align: start;
  white-space: normal;
  z-index: 2;
}

.viewMode {
  border: 1px solid rgba(0, 0, 0, 0) !important;
}

.hasError {
  border: 1px solid rgba(143, 22, 0, 0.75) !important;
  background-color: rgba(143, 22, 0, 0.1) !important;
}
