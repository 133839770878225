.topBar {
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  padding: 2px;
  align-items: center;
  border-radius: 0 !important;
  background: #e8eef0 !important;
  z-index: 500;
  height: 55px;
}

.logoWrapper {
  cursor: pointer;
  padding: 0.8em;
}

.activeSection {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: bold !important;
}

.activeMobileSelection {
  font-size: 1.14285714rem;
}

.hamburgerButton {
  border: 0 !important;
  background: transparent !important;
}
