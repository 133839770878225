pre.httpBody {
  white-space: pre-wrap;
  word-wrap: break-word;
}

div.segmentContent {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

div.headerList {
  margin-left: 1em !important;
}

div.headerName {
  color: #943634 !important;
}

.headerTabTitle {
  padding: 0 0 0 1em !important;
}

div.queryParameterList {
  margin-left: 1em !important;
  margin-top: 0 !important;
}

div.httpBodyContent {
  margin-left: 1em !important;
}

div.httpBodyFormatCheckbox {
  margin-left: 1em !important;
  margin-bottom: 1em !important;
}

.monospaceValue {
  font-family: monospace;
}
