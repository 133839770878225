div.tableContainer {
  flex-grow: 1;
  min-height: 0;
}

table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  background-color: #eeeeee !important;
}

table thead {
  flex: 0 0 auto;
  width: 100%;
}
table tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}
tbody::-webkit-scrollbar {
  width: 0.86em !important;
}
table tbody tr {
  width: 100%;
  background-color: white;
}

table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

table tfoot,
table tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table thead th a {
  cursor: pointer;
}

table tbody tr td {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
  border-top: 0 !important;
}

tr.fullHeight {
  height: 100%;
}

.resultsContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cellWrapper {
  display: flex;
}

.headerCellWrapper {
  display: flex;
  height: 1.75em;
  align-items: center;
}

.flexWrapper {
  max-width: 100%;
}

.headerCell {
  height: 20px;
  width: 100%;
}

.tableHeader {
  padding: 0.5em !important;
}

:global(.ui.striped.table tbody tr:nth-child(2n)) {
  background-color: rgb(245, 245, 249) !important;
}

.paginationPopup {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.paginationPopupInput {
  flex-grow: 1;
}
