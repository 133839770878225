.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

:global(#root .App) .card {
  max-width: 400px;
  min-width: 300px;
  width: 25%;
}

.loginHeader img {
  width: 2em;
  margin-right: 0.5em;
}

.versionNumber {
  opacity: 0.25;
  position: absolute;
  bottom: 5em;
}

.formError {
  color: var(--colorError);
  margin-bottom: 1em;
}
