.appFrame {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0 !important;
}

.topBar {
  margin: 0 !important;
  border-radius: 0 !important;
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  min-height: 50px;
  height: 50px !important;
}

.mainArea {
  flex-grow: 1;
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  display: flex !important;
  flex-direction: row;
  height: 100%;
}

.contentArea {
  flex-grow: 1;
  margin: 0 !important;
  overflow-y: auto;
  height: calc(100vh - 50px);
}

.activeSection {
  color: rgba(0, 0, 0, 0.6) !important;
}

.activeSection:hover {
  color: rgba(255, 255, 255, 0.6) !important;
}
