.splashScreen {
  background: var(--colorThemeLight) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  position: inherit !important;
}
