.field {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  align-items: baseline;
}

.label {
  color: rgba(0, 0, 0, 0.5);
}

.labelWrapper {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.formError {
  color: #8f1600;
  margin-left: 2em;
}

.input {
  display: inline-block;
  padding: 0 !important;
  margin: 0.45238095em 0 0.45238095em 0.64285714em !important;
  position: static;
  width: 2.2em;
  line-height: 1.21428571em;
  border: none !important;
  max-width: 100%;
  box-shadow: none !important;
  outline: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.inputViewMode {
  pointer-events: none !important;
}

.bordersEditMode {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.28571429rem;
}

.bordersViewMode {
  border: 1px solid rgba(34, 36, 38, 0) !important;
  border-radius: 0.28571429rem;
}

.bordersError {
  border: 1px solid rgba(143, 22, 0, 0.75) !important;
  background-color: rgba(143, 22, 0, 0.1) !important;
  border-radius: 0.28571429rem;
}

.container {
  width: 100%;
  padding: 0.22619048em 0.22619048em 0.22619048em 0.35714286em;
}

.containerViewMode {
}

.containerEditMode {
  cursor: text;
}

.containerHasError {
}

.selectedValue {
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0 !important;
}

.disabledDeleteIcon {
  color: rgba(0, 0, 0, 0.15) !important;
}

.sizer {
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  display: none;
  white-space: pre;
}

.confirmInputButton {
  cursor: pointer;
}
