.baseButton {
  display: flex !important;
  min-height: unset !important;
}

.hiddenButton {
  visibility: hidden !important;
}

.rightIcon {
  margin-left: 0.5em !important;
  height: 1em !important;
}

.leftIcon {
  height: 1em !important;
}
