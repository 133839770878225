.field {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  align-items: baseline;
}

.label {
  color: rgba(0, 0, 0, 0.5);
}

.labelWrapper {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.formError {
  color: #8f1600;
  margin-left: 2em;
}

.viewMode {
  border: 1px solid rgba(34, 36, 38, 0) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.hasError input {
  border: 1px solid rgba(143, 22, 0, 0.75) !important;
  background-color: rgba(143, 22, 0, 0.1) !important;
}
