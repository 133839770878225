.wordWrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.innerHorizontalScroll {
  overflow-x: auto;
}

.innerVerticalScroll {
  overflow-y: auto;
}
