.toggleButton {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.searchField {
  width: 30em;
}

.root {
  display: flex;
  height: 64px !important;
  margin-bottom: 0;
  margin-top: 0;
  align-items: center;
  min-height: 64px !important;
}

.grow {
  flex-grow: 1;
}

.resetAllButton {
  width: 100%;
}
