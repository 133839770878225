.filterLabelWrapper {
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin-bottom: 0.5em;
}

.topLabel {
  flex-grow: 1;
  margin: 0 !important;
  font-weight: bold;
}

.separatorLabel {
  flex-grow: 1;
  font-weight: bold;
}

.grow {
  flex-grow: 1;
}

.noMargin {
  margin: 0 !important;
}
