.logoutContainer {
  position: absolute;
  bottom: 0;
}

.logoutItem {
  position: absolute;
  top: auto !important;
  bottom: 0 !important;
}

:global(#root .App) .sideBar {
  border-radius: 0;
  border: 0;
  height: 100%;
  min-width: 160px;
  width: 160px;
  flex-grow: 1;
  box-shadow: none !important;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background: #fff;
  height: calc(100vh - 50px);
  width: 160px !important;
  transition: transform 0.15s ease !important;
}

.mobileSidebarContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background: #fff;
  border: 0 !important;
  width: 100%;
}

.mobileSidebarContentArea {
  min-height: auto !important;
}

.versionNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.versionNumberText {
  opacity: 0.5;
  text-align: center;
}

.active {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
