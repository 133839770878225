.base {
  display: flex;
}

.columnReverse {
  flex-direction: column-reverse;
}

.column {
  flex-direction: column;
}

.reverse {
  flex-direction: row-reverse;
}

.wrap {
  flex-flow: wrap;
}

.interactable {
  cursor: pointer;
}

.interactable:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: 0.15s;
}

.interactable:active {
  background-color: rgba(0, 0, 0, 0.025);
  transition: 0s;
}
